import React from 'react'
//
import { getSocialIcon } from '~utils/social-icons'
import NavLink from '~utils/navlink'
import FormattedText from '~utils/text'

export const Footer = ({ textBlock, linkOptions, socialLinks, logo = {} }) => (
  <>
    {(linkOptions.length || socialLinks.length || textBlock.title || textBlock.content) && (
      <footer className="bg-c-footer-bg text-c-footer-text w-full flex relative">
        <div className="relative container max-w-7xl flex flex-col content-center justify-between mx-auto px-6 md:px-10">
          <div className={textBlock ? 'grid grid-cols-1 lg:grid-cols-2 py-10 lg:py-20' : 'py-10 lg:py-20'}>
            {textBlock && (
              <div className="md:max-w-xl lg:max-w-md mb-10">
                {textBlock?.title ? (
                  <h4 className="font-thin text-c-footer-textBlockHeadingText mb-4">{textBlock?.title}</h4>
                ) : null}
                <FormattedText text={textBlock?.content} format={textBlock?.content || 'html'} />
                {logo?.url ? (
                  <div className="mt-8 mb-2">
                    <img src={logo.url} alt={logo?.alt} />
                  </div>
                ) : null}
              </div>
            )}
            {linkOptions && (
              <div
                className={
                  textBlock
                    ? 'grid grid-cols-1 md:grid-cols-2 gap-2'
                    : 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-2'
                }
              >
                {Array.isArray(linkOptions) &&
                  linkOptions.map((opt, idx1) => (
                    <div className="flex flex-col mb-10" key={`footer-col-${opt?.id}-${idx1}`}>
                      <div className="font-thin text-c-footer-hoverLinkText mb-4">{opt?.label}</div>
                      {Array.isArray(opt?.items) &&
                        opt?.items.map((link, idx2) => (
                          <NavLink
                            className="text-c-footer-linkText font-thin no-underline hover:text-c-footer-hoverLinkText hover:underline"
                            key={`footer-${link?.id}-${idx2}`}
                            to={link?.url}
                          >
                            {link?.label}
                          </NavLink>
                        ))}
                    </div>
                  ))}
              </div>
            )}
          </div>
          <div className="py-6 mx-auto text-center">
            {Array.isArray(socialLinks) &&
              socialLinks.map((link, idx) => (
                <NavLink
                  className="text-c-footer-linkText hover:text-c-footer-hoverLinkText cursor-pointer mx-1"
                  key={`social-${link?.url}-${idx}`}
                  to={link?.url}
                >
                  {getSocialIcon(link?.url)}
                </NavLink>
              ))}
          </div>
        </div>
      </footer>
    )}
  </>
)

export default Footer
